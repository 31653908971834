<template>
  <div class="premiados">
    <h2>Premiados de outros sorteios</h2>
    <!-- Premio -->
    <div class="ganhador" v-for="(ganhador, index) in state.ganhadores" :key="index">
      <div class="bilhete">
        <p>{{ ganhador.bilhete }}</p>
      </div>
      <h3>{{ ganhador.nome }}</h3>
      <div class="premio">
        <p>{{ ganhador.premio }}</p>
        <div class="imagem" :style="`background-image: url('${ganhador.imagemPremio}')`"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue'

const state = reactive({
  ganhadores: [
    {
      bilhete: '522567',
      nome: 'Wesley Oliveira',
      premio: 'Iphone 15 Pro Max',
      imagemPremio: 'https://arquivos.oficinadasrifas.com/imagens/imagem-iphone.png'
    },
    {
      bilhete: '215647',
      nome: 'Luiz Eduardo',
      premio: 'Jetta 2024 0km',
      imagemPremio: 'https://arquivos.oficinadasrifas.com/imagens/carro-1.png'
    },
    {
      bilhete: '421451',
      nome: 'Steffany Lopes',
      premio: 'Moto Hornet 0km 2024',
      imagemPremio: 'https://arquivos.oficinadasrifas.com/imagens/imagem-moto.png'
    }
  ]
})
</script>

<style scoped>
.premiados {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: var(--cor-escuro-2);
  margin: 30px 0 0 0;
  padding: 40px;
}

h2 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  text-align: center;
  margin: 0 0 20px 0;
}

.ganhe {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: var(--cor-escuro-3);
  border-radius: 10px;
  height: 80px;
  padding: 0 20px;
}

.ganhe .bilhete {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-azul);
  height: 35px;
  width: 100px;
  border-radius: 5px;
}

.ganhe .bilhete p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-align: center;
}

.ganhe h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 0 20px;
}

.ganhador {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-escuro-3);
  border-radius: 10px;
  height: 80px;
  padding: 0 20px;
  width: 100%;
  margin: 10px 0 0 0;
}

.ganhador .bilhete {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-escuro-2);
  height: 35px;
  width: 100px;
  border-radius: 5px;
  margin: 0 20px 0 0;
}

.ganhador .bilhete p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-align: center;
}

.ganhador h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin-right: auto;
}

.ganhador .premio {
  display: flex;
  align-items: center;
}

.ganhador .premio p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.ganhador .premio .imagem {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  margin: 0 0 0 10px;
}

@media screen and (max-width: 1000px) {
  .premiados {
    margin: 20px 0 0 0;
    padding: 30px 20px;
  }

  h2 {
    font-size: var(--f3);
  }

  .ganhe .bilhete {
    width: 80px;
  }

  .ganhe .bilhete p {
    font-size: var(--f1);
  }

  .ganhe h3 {
    font-size: var(--f2);
  }

  .ganhador {
    flex-wrap: wrap;
    height: auto;
    padding: 15px;
    width: 100%;
    margin: 10px 0 0 0;
  }

  .ganhador .bilhete {
    width: 80px;
  }

  .ganhador .bilhete p {
    font-size: var(--f1);
  }

  .ganhador .premio {
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: 100%;
    margin: 15px 0 0 0;
    background-color: var(--cor-escuro-4);
    border-radius: 5px;
  }

  .ganhador .premio p {
    font-size: var(--f2);
  }

  .ganhador .premio .imagem {
    width: 80px;
    height: 80px;
    margin: 0 20px 0 0;
  }
}
</style>
