<template>
  <section class="rifas">
    <!-- Item do back -->
    <div class="item" v-for="(rifa, index) in state.rifas" :key="index">
      <!-- Foto -->
      <div class="foto" :style="`background-image: url('${rifa.foto}')`"></div>
      <!-- Info -->
      <div class="info">
        <h2>{{ rifa.titulo }}</h2>
        <p>Apenas {{ rifa.valorPorBilhete }} por bilhete</p>
      </div>

      <div class="temporizador">
        <div class="texto">
          <h3>Sorteio finalizado em</h3>
          <p>{{ rifa.dataFinal }}</p>
        </div>
        <div class="vencedor">
          <Svgs nome="usuario" />
          <div class="nome">
            <p>Vencedor</p>
            <h3>{{ rifa.vencedor }}</h3>
          </div>
          <h4>{{ rifa.bilheteVencedor }}</h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue'
import Svgs from '@svgs'

const state = reactive({
  rifas: [
    {
      titulo: 'Carro Modificado Turbo',
      valorPorBilhete: 'R$ 0,20',
      dataFinal: '20/12/2024 às 20h30',
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-2.png',
      vencedor: 'Wesley Silva',
      bilheteVencedor: '12.124.421'
    },
    {
      titulo: 'Carro Wolkswagem NOVO',
      valorPorBilhete: 'R$ 0,20',
      dataFinal: '15/12/2024 às 20h30',
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-3.png',
      vencedor: 'Wesley Silva',
      bilheteVencedor: '12.124.421'
    },
    {
      titulo: 'Moto Turbo 0km',
      valorPorBilhete: 'R$ 0,20',
      dataFinal: '20/02/2024 às 20h30',
      foto: 'https://arquivos.oficinadasrifas.com/imagens/imagem-moto.png',
      vencedor: 'Wesley Silva',
      bilheteVencedor: '12.124.421'
    },
    {
      titulo: 'Carro 0km NOVO',
      valorPorBilhete: 'R$ 0,20',
      dataFinal: '20/02/2024 às 20h30',
      foto: 'https://arquivos.oficinadasrifas.com/imagens/carro-4.png',
      vencedor: 'Pedro Ricardo',
      bilheteVencedor: '12.312.521'
    },
    {
      titulo: 'Iphone 14 Pro Max 512gb',
      valorPorBilhete: 'R$ 0,50',
      dataFinal: '20/02/2024 às 20h30',
      foto: 'https://arquivos.oficinadasrifas.com/imagens/imagem-iphone.png',
      vencedor: 'Rodrigo Silva',
      bilheteVencedor: '11.312.421'
    }
  ]
})
</script>

<style scoped>
section.rifas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 30px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-escuro-2);
  padding: 10px 25px 10px 10px;
  border-radius: 10px;
  margin: 20px 0 0 0;
}

.item .foto {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.item .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 0 0 20px;
  width: 100%;
  max-width: 400px;
}

.item .info h2 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.item .info p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.item .info .faixa {
  width: 100%;
  height: 4px;
  border-radius: 10px;
  background-color: var(--cor-escuro-3);
  position: relative;
}

.item .info .faixa .atual {
  height: 4px;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.item .info .faixa.ativo .atual {
  background-color: var(--cor-verde);
}

.item .info .faixa .atual {
  background-color: var(--cor-vermelho);
}

.item .info .quantidade {
  display: flex;
  align-items: center;
  margin: 15px 0 0 0;
}

.item .info .quantidade h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza);
}

.item .info .quantidade h4 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-escuro-5);
  margin: 0 0 0 5px;
}

.temporizador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.temporizador .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin: 5px 10px 0 0;
  min-width: 200px;
}

.temporizador .texto h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.temporizador .texto p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.temporizador .tempo {
  display: flex;
  align-items: center;
}

.temporizador .tempo .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0 8px 0;
  width: 50px;
  border-radius: 5px;
  background-color: var(--cor-escuro-3);
  border-bottom: 2px solid var(--cor-azul);
  margin: 0 0 0 8px;
}

.temporizador .tempo .item h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.temporizador .tempo .item p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.temporizador button {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  background-color: var(--cor-verde);
  padding: 20px 30px;
  border-radius: 10px;
  margin: 0 0 0 30px;
  transition: all 0.3s;
}

.temporizador button:hover {
  background-color: var(--cor-verde-escuro);
}

.temporizador .vencedor {
  display: flex;
  align-items: center;
  background-color: var(--cor-escuro-3);
  min-width: 350px;
  padding: 20px;
  border-radius: 10px;
  margin: 0 0 0 10px;
}

.temporizador .vencedor svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-cinza);
  margin: 0 10px 0 0;
}

.temporizador .vencedor .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.temporizador .vencedor .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.temporizador .vencedor .nome h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.temporizador .vencedor h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: #ffd119;
  background-color: #ffd11921;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  section.rifas {
    padding: 0 20px;
  }

  .item {
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--cor-escuro-2);
    padding: 10px;
    border-radius: 10px;
    margin: 20px 0 0 0;
  }

  .item .foto {
    width: 100px;
    height: 100px;
  }

  .item .info {
    padding: 0 10px 0 15px;
    width: calc(100% - 100px);
    max-width: calc(100% - 100px);
  }

  .item .info h2 {
    font-size: var(--f3);
    margin: 10px 0 12px 0;
  }

  .item .info .faixa {
    height: 3px;
  }

  .item .info .faixa .atual {
    height: 3px;
  }

  .item .info .quantidade {
    margin: 12px 0 0 0;
  }

  .temporizador {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .temporizador .texto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin: 25px 10px 0 0;
    min-width: auto;
  }

  .temporizador .tempo .item {
    width: 55px;
    margin: 20px 10px 0 0;
  }

  .temporizador button {
    margin: 20px 0 0 0;
  }

  .temporizador .vencedor {
    min-width: auto;
    width: 100%;
    padding: 15px;
    margin: 20px 0 0 0;
  }
}
</style>
