import helperToken from '@helpers/token'
import { api } from './axios'

const token = helperToken.receberToken()

export default {
  receberPorId(idRifa) {
    const method = 'GET'
    const url = `rifas/id/${idRifa}`

    return api(method, url, null, token)
  },

  receberPorHotlink(hotlink) {
    const method = 'GET'
    const url = `rifas/hotlink/${hotlink}`

    return api(method, url, null, token)
  },

  receberTodos() {
    const method = 'GET'
    const url = `rifas`

    return api(method, url, null, token)
  }
}
