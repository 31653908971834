<template>
  <section class="modal" :class="{ mostrar: storeRifas.pagarPix }">
    <div class="quadro">
      <button class="fechar" @click="storeRifas.pagarPix = false">
        <Svgs nome="x" />
      </button>
      <div class="titulo">
        <Svgs nome="pix" />
        <h3>Compre {{ storeRifas.quantidadeBilhetes }} bilhetes</h3>
        <p>Por apenas R$ {{ storeRifas.totalPagar }}</p>
      </div>
      <div class="pagar">
        <div class="qrcode">
          <Svgs nome="qrcode" />
        </div>
        <p>Pix Copia e Cola:</p>
        <textarea rows="4" spellcheck="false" readonly>00020126360014BR.GOV.BCB.PIX0114+5511999999999215204000053039865405100.005802BR5920Nome do Recebedor6009SAO PAULO61080540900062070503***6304A13B</textarea>
      </div>
      <div class="tempo">
        <Svgs nome="relogio" />
        <p>Após pagar, os bilhetes serão adicionados em sua conta em até 5 minutos</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStoreRifas } from '@stores'
import Svgs from '@svgs'

const storeRifas = useStoreRifas()
</script>

<style scoped>
section.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000ba;
  min-height: 100dvh;
  width: 100%;
  padding: 20px 20px 100px 20px;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 20;
  transition: all 0.3s;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 450px;
  border: 1px solid var(--cor-escuro-4);
  background-color: #171718e4;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 10px;
  position: relative;
}

.quadro button.fechar {
  width: 50px;
  height: 50px;
  background-color: var(--cor-escuro-1);
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: -60px;
  transition: all 0.3s;
}

.quadro button.fechar:hover {
  background-color: var(--cor-vermelho);
}

.quadro button.fechar svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco);
}

.quadro .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 0;
}

.quadro .titulo svg {
  width: 40px;
  min-width: 40px;
  fill: #15ec4e;
}

.quadro .titulo h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 15px 0 5px 0;
}

.quadro .titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: #15ec4e;
}

.quadro .pagar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  border-top: 2px solid var(--cor-escuro-4);
  padding: 30px;
}

.quadro .pagar .qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 5px;
  background-color: white;
}

.quadro .pagar .qrcode svg {
  width: 140px;
  min-width: 140px;
  fill: black;
}

.quadro .pagar textarea {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 1.5;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  max-width: 350px;
  background-color: var(--cor-escuro-3);
  resize: none;
}

.quadro .pagar p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 15px 0 5px 0;
}

.quadro .tempo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-top: 2px solid var(--cor-escuro-4);
  padding: 30px;
}

.quadro .tempo svg {
  width: 28px;
  min-width: 28px;
  fill: #fec118;
  margin: 0 10px 0 0;
}

.quadro .tempo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  text-align: left;
  width: 100%;
  max-width: 300px;
}

@media screen and (max-width: 1000px) {
  .quadro button.fechar {
    top: -60px;
    right: 0;
  }
}
</style>
