<template>
  <div class="regulamento" :class="{ ativo: state.regulamento }">
    <div class="topo" @click="state.regulamento = !state.regulamento">
      <h3>Veja a descrição e regulamentos</h3>
      <Svgs nome="setinha" />
    </div>
    <div class="conteudo">
      <p>{{ storeRifas.rifa?.regulamento }}</p>
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import { useStoreRifas } from '@stores'
import Svgs from '@svgs'

const storeRifas = useStoreRifas()

const state = reactive({
  regulamento: false
})
</script>

<style scoped>
.regulamento {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px 0 0 0;
}

.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  background-color: var(--cor-escuro-2);
  border-bottom: 1px solid var(--cor-escuro-2);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.topo:hover {
  background-color: var(--cor-escuro-3);
  border-bottom: 1px solid var(--cor-escuro-3);
}

.regulamento.ativo .topo {
  border-bottom: 1px solid var(--cor-escuro-3);
  border-radius: 10px 10px 0 0;
}

.topo h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.regulamento.ativo .topo svg {
  transform: rotate(270deg);
}

.topo svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transform: rotate(90deg);
  transition: all 0.3s;
}

.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0;
  border-radius: 0 0 10px 10px;
  background-color: var(--cor-escuro-2);
  transition: all 0.3s;
}

.regulamento.ativo .conteudo {
  padding: 30px;
}

.conteudo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  line-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.regulamento.ativo .conteudo p {
  opacity: 1;
  visibility: visible;
  line-height: 1.5;
}
@media screen and (max-width: 1000px) {
  .regulamento {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .topo {
    padding: 20px;
  }

  .regulamento.ativo .conteudo {
    padding: 20px;
  }
}
</style>
