<template>
  <section class="topo"></section>
</template>

<script setup></script>

<style scoped>
section.topo {
  display: flex;
  animation: fadeIn 0.3s linear;
}
@media screen and (max-width: 1000px) {
}
</style>
