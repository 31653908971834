<template>
  <section class="imagens">
    <div class="slide">
      <div class="item" v-for="(imagem, index) in storeRifas.rifa?.fotos" :key="index" @click="mudarImagem(index)">
        <div class="imagem" :style="`background-image: url('${imagem}')`"></div>
      </div>
    </div>
    <div class="fundo" :style="`background-image: url('${storeRifas.rifa?.fotos[imagemAtual]}')`">
      <div class="sombra">
        <h3>{{ storeRifas.rifa?.titulo }}</h3>
        <p>{{ storeRifas.rifa?.descricao }}</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { useStoreRifas } from '@stores'

const storeRifas = useStoreRifas()
const imagemAtual = ref(0)

function mudarImagem(index) {
  imagemAtual.value = index
  resetarIntervalo()
}
</script>

<style scoped>
section.imagens {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  background-color: var(--cor-escuro-2);
  border-radius: 10px;
  position: fixed;
  top: 100px;
  left: 30px;
  min-height: calc(100dvh - 140px);
  z-index: 10;
}

.fundo {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  z-index: 1;
}

.fundo .sombra {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 30px;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, #111112cb, #11111200, #111112cb);
  background: -webkit-linear-gradient(90deg, #111112cb, #11111200, #111112cb);
  z-index: 2;
}

.fundo .sombra h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.fundo .sombra p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
}

.slide {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 30px;
  overflow-x: scroll;
  scrollbar-width: none;
  z-index: 3;
}

.slide::-webkit-scrollbar {
  display: none;
}

.slide .item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

.slide .item:hover {
  transform: scale(0.9);
}

.slide .item .imagem {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  transition: all 0.3s;
}

.slide .item:hover .imagem {
  filter: brightness(110%);
}

@media screen and (max-width: 1000px) {
  section.imagens {
    width: 100%;
    height: 350px;
    min-height: 350px;
    position: relative;
    left: 0;
    top: 0;
    z-index: 3;
  }
  .fundo {
    border-radius: 0;
  }

  .fundo .sombra {
    align-items: center;
    border-radius: 0;
    padding: 20px;
    background: linear-gradient(90deg, #111112, #11111200);
    background: -webkit-linear-gradient(90deg, #111112, #11111200);
  }

  .fundo .sombra h3 {
    font-size: var(--f4);
    text-align: center;
  }

  .fundo .sombra p {
    font-size: var(--f0);
    margin: 8px 0 0 0;
    text-align: center;
  }

  .slide {
    display: none;
    align-items: center;
    justify-content: center;
    top: auto;
    bottom: 65px;
    left: 0;
    padding: 20px 50px;
    overflow-x: none;
  }

  .slide .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    transition: all 0.3s;
  }

  .slide .item:hover {
    transform: scale(0.9);
  }

  .slide .item .imagem {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-image: inherit !important;
    background-color: var(--cor-branco);
    opacity: 0.5;
  }
}
</style>
