<template>
  <section class="modal" :class="{ mostrar: storeRifas.titulosPremiados }">
    <div class="quadro">
      <button class="fechar" @click="storeRifas.titulosPremiados = false">
        <Svgs nome="x" />
      </button>
      <div class="titulo">
        <Svgs nome="medalha" />
        <h3>Títulos Premiados</h3>
      </div>
      <div class="lista">
        <div class="item" v-for="(item, index) in state.itens" :key="`premiados-${index}`">
          <div class="nome">
            <h3>{{ item.ganhador }}</h3>
            <p>{{ item.premio }}</p>
          </div>
          <div class="bilhete">
            <p>{{ item.bilhete }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue'
import { useStoreRifas } from '@stores'
import Svgs from '@svgs'

const storeRifas = useStoreRifas()

const state = reactive({
  itens: [
    {
      ganhador: 'Pedro Silva',
      premio: 'Prêmio: R$ 1.000',
      bilhete: '2.312.512'
    },
    {
      ganhador: 'Ana Oliveira',
      premio: 'Prêmio: R$ 500',
      bilhete: '3.521.774'
    },
    {
      ganhador: 'Carlos Santos',
      premio: 'Prêmio: R$ 2.000',
      bilhete: '1.785.349'
    },
    {
      ganhador: 'Mariana Costa',
      premio: 'Prêmio: R$ 1.500',
      bilhete: '9.102.365'
    },
    {
      ganhador: 'João Pereira',
      premio: 'Prêmio: R$ 750',
      bilhete: '4.986.223'
    },
    {
      ganhador: 'Fernanda Gomes',
      premio: 'Prêmio: R$ 1.200',
      bilhete: '8.453.112'
    },
    {
      ganhador: 'Ricardo Lima',
      premio: 'Prêmio: R$ 3.000',
      bilhete: '5.781.990'
    },
    {
      ganhador: 'Beatriz Souza',
      premio: 'Prêmio: R$ 600',
      bilhete: '7.412.658'
    },
    {
      ganhador: 'Lucas Fernandes',
      premio: 'Prêmio: R$ 1.800',
      bilhete: '6.329.874'
    },
    {
      ganhador: 'Patrícia Rocha',
      premio: 'Prêmio: R$ 2.500',
      bilhete: '2.896.413'
    },
    {
      ganhador: 'Gustavo Almeida',
      premio: 'Prêmio: R$ 900',
      bilhete: '3.114.722'
    }
  ]
})
</script>

<style scoped>
section.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000000ba;
  min-height: 100dvh;
  width: 100%;
  padding: 20px 20px 100px 20px;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 20;
  transition: all 0.3s;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 450px;
  border: 1px solid var(--cor-escuro-4);
  background-color: #171718e4;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 10px;
  position: relative;
}

.quadro button.fechar {
  width: 50px;
  height: 50px;
  background-color: var(--cor-escuro-1);
  border-radius: 10px;
  position: absolute;
  top: 0;
  right: -60px;
  transition: all 0.3s;
}

.quadro button.fechar:hover {
  background-color: var(--cor-vermelho);
}

.quadro button.fechar svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-branco);
}

.quadro .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 0;
}

.quadro .titulo svg {
  width: 40px;
  min-width: 40px;
  fill: #ecc515;
  margin: 0 0 10px 0;
}

.quadro .titulo h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro .lista {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 50dvh;
  min-height: 50dvh;
  max-height: 50dvh;
  overflow-y: scroll;
  border-top: 1px solid var(--cor-escuro-4);
  padding: 0 30px;
}

.quadro .lista .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--cor-escuro-4);
  padding: 20px 0;
}

.quadro .lista .item .nome {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.quadro .lista .item .nome h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.quadro .lista .item .nome p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza);
  margin: 5px 0 0 0;
}

.quadro .lista .item .bilhete {
  padding: 5px 8px;
  border-radius: 5px;
  background-color: var(--cor-verde);
}

.quadro .lista .item .bilhete p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}
@media screen and (max-width: 1000px) {
  .quadro button.fechar {
    top: -60px;
    right: 0;
  }
}
</style>
