<template>
  <section class="comprar">
    <!-- Section Imagens -->
    <SectionImagens />
    <!-- Quadro -->
    <div class="quadro">
      <!-- Topo -->
      <div class="topo">
        <div class="texto">
          <h3>Sorteio será realizado em</h3>
          <p>{{ formatarDataHora(storeRifas.rifa?.dataFinal) }}</p>
        </div>
        <div class="tempo">
          <div class="item">
            <h3>{{ state.dias }}</h3>
            <p>Dias</p>
          </div>
          <div class="item">
            <h3>{{ state.horas }}</h3>
            <p>Horas</p>
          </div>
          <div class="item">
            <h3>{{ state.minutos }}</h3>
            <p>Min</p>
          </div>
          <div class="item">
            <h3>{{ state.segundos }}</h3>
            <p>Seg</p>
          </div>
        </div>
      </div>
      <!-- Quantidade -->
      <div class="quantidade">
        <div class="titulo">
          <h3>Escolha sua sorte abaixo</h3>
          <p>Apenas R$ 0,20 por número</p>
        </div>
        <div class="itens">
          <div class="item" v-for="(pacote, index) in state.pacotes" :key="index" :class="{ popular: pacote.popular }" @click="adicionarPacote(pacote.quantidade)">
            <h4 v-if="pacote.popular">POPULAR</h4>
            <h3>{{ pacote.quantidade }}</h3>
            <button>Selecionar</button>
          </div>
        </div>
        <div class="total">
          <button @click="adicionarBilhetes(-10)">
            <Svgs nome="menos" />
          </button>
          <p>
            {{ state.totalBilhetes }} bilhetes
            <span v-if="state.totalBilhetes >= 1" @click="zerarBilhetes()">ZERAR</span>
          </p>
          <button @click="adicionarBilhetes(10)">
            <Svgs nome="mais" />
          </button>
        </div>
        <div class="confirmar">
          <button @click="storeRifas.pagarPix = true">
            <p>Participar do Sorteio</p>
            <h4>R$ {{ state.totalCompra.toFixed(2) }}</h4>
          </button>
        </div>
        <div class="extras">
          <button @click="storeRifas.login = true">
            <Svgs nome="cupom" />
            <p>Ver meus bilhetes</p>
          </button>
          <button class="titulos" @click="storeRifas.titulosPremiados = true">
            <Svgs nome="medalha" />
            <p>Títulos Premiados</p>
          </button>
        </div>
      </div>
      <!-- Regulamentos -->
      <DivRegulamento />
      <!-- Premiados -->
      <DivPremiados />
    </div>
  </section>
</template>

<script setup>
import { reactive, watch, onUnmounted } from 'vue'
import { useStoreRifas } from '@stores'
import Svgs from '@svgs'
import SectionImagens from '@components/app/inicio/partials/SectionImagens.vue'
import DivPremiados from '@components/app/inicio/partials/DivPremiados.vue'
import DivRegulamento from '@components/app/inicio/partials/DivRegulamento.vue'

const storeRifas = useStoreRifas()

const state = reactive({
  dias: 0,
  horas: 0,
  minutos: 0,
  segundos: 0,
  dataFinal: storeRifas.rifa?.dataFinal,
  totalBilhetes: 0,
  valorBilhete: 0.2,
  totalCompra: 0,
  pacotes: [
    {
      popular: false,
      quantidade: 100
    },
    {
      popular: true,
      quantidade: 300
    },
    {
      popular: false,
      quantidade: 500
    },
    {
      popular: false,
      quantidade: 1000
    }
  ]
})

function formatarDataHora(dataISO) {
  const data = new Date(dataISO)

  const dia = String(data.getUTCDate()).padStart(2, '0')
  const mes = String(data.getUTCMonth() + 1).padStart(2, '0')
  const ano = data.getUTCFullYear()

  const horas = String(data.getUTCHours()).padStart(2, '0')
  const minutos = String(data.getUTCMinutes()).padStart(2, '0')

  return `${dia}/${mes}/${ano} às ${horas}:${minutos}`
}

function zerarBilhetes() {
  state.totalBilhetes = 0
  storeRifas.quantidadeBilhetes = 0
  state.totalCompra = 0
  storeRifas.totalPagar = 0
}

function adicionarBilhetes(quantidade) {
  state.totalBilhetes += quantidade
  storeRifas.quantidadeBilhetes += quantidade
  if (state.totalBilhetes < 0) state.totalBilhetes = 0
  state.totalCompra = state.totalBilhetes * state.valorBilhete
  storeRifas.totalPagar = state.totalBilhetes * state.valorBilhete
}

function adicionarPacote(quantidade) {
  state.totalBilhetes += quantidade
  storeRifas.quantidadeBilhetes += quantidade
  state.totalCompra = state.totalBilhetes * state.valorBilhete
  storeRifas.totalPagar = state.totalBilhetes * state.valorBilhete
}

function calcularTempoRestante() {
  if (!state.dataFinal) return

  const dataFutura = new Date(state.dataFinal)
  const agora = new Date()
  const diferenca = dataFutura - agora

  if (diferenca > 0) {
    state.dias = Math.floor(diferenca / (1000 * 60 * 60 * 24))
    state.horas = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    state.minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60))
    state.segundos = Math.floor((diferenca % (1000 * 60)) / 1000)
  } else {
    state.dias = 0
    state.horas = 0
    state.minutos = 0
    state.segundos = 0
    clearInterval(interval)
  }
}

let interval

watch(
  () => storeRifas.rifa?.dataFinal,
  (novaDataFinal) => {
    if (novaDataFinal) {
      state.dataFinal = novaDataFinal
      calcularTempoRestante()
      interval = setInterval(calcularTempoRestante, 1000)
    }
  },
  { immediate: true }
)

onUnmounted(() => {
  clearInterval(interval)
})
</script>

<style scoped>
section.comprar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 100px 30px 30px 30px;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(50% - 50px);
  background-color: var(--cor-escuro-1);
  border-radius: 10px;
  margin-left: auto;
}

.topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--cor-escuro-4);
  background-color: var(--cor-escuro-2);
  padding: 30px;
  border-radius: 10px 10px 0 0;
}

.topo .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.topo .texto p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.topo .tempo {
  display: flex;
  align-items: center;
}

.topo .tempo .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 10px 0;
  width: 55px;
  border-radius: 5px;
  background-color: var(--cor-escuro-3);
  border-bottom: 2px solid var(--cor-azul);
  margin: 0 0 0 10px;
}

.topo .tempo .item h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.topo .tempo .item p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.quantidade {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 40px;
  background-color: var(--cor-escuro-2);
  border-radius: 0 0 10px 10px;
}

.quantidade .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 0 50px 0;
}

.quantidade .titulo h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.quantidade .titulo p {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.quantidade .itens {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.quantidade .itens .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: calc(25% - 10px);
  background-color: var(--cor-escuro-3);
  border: 1px solid var(--cor-escuro-3);
  border-radius: 10px;
  position: relative;
  padding: 40px 0 0 0;
  cursor: pointer;
  transition: all 0.3s;
}

.quantidade .itens .item:hover {
  background-color: var(--cor-escuro-4);
  border: 1px solid var(--cor-escuro-4);
}

.quantidade .itens .item.popular:hover {
  background-color: var(--cor-escuro-2);
  border: 1px solid var(--cor-branco);
}

.quantidade .itens .item.popular {
  border: 1px solid var(--cor-azul);
  background-color: var(--cor-escuro-1);
}

.quantidade .itens .item .icone {
  width: 60px;
  height: 60px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.quantidade .itens .item h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  margin: 10px 0 30px 0;
}

.quantidade .itens .item h4 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: var(--cor-azul);
  padding: 7px 15px;
  border-radius: 50px;
  position: absolute;
  top: -15px;
}

.quantidade .itens .item button {
  width: 100%;
  background-color: transparent;
  border-top: 1px solid var(--cor-escuro-4);
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-escuro-5);
  padding: 15px;
  transition: all 0.3s;
}

.quantidade .itens .item.popular:hover button {
  border-top: 1px solid var(--cor-branco);
}

.quantidade .itens .item.popular button {
  color: var(--cor-branco);
  border-top: 1px solid var(--cor-azul);
}

.quantidade .total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--cor-escuro-3);
  border-radius: 10px;
  margin: 20px 0 0 0;
}

.quantidade .total button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: transparent;
  border-radius: 10px;
  transition: all 0.3s;
}

.quantidade .total button:hover {
  background-color: var(--cor-escuro-4);
}

.quantidade .total button svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
}

.quantidade .total p {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.quantidade .total p span {
  font-size: var(--f1);
  padding: 8px 12px;
  border-radius: 50px;
  color: var(--cor-cinza);
  background-color: var(--cor-escuro-4);
  margin: 0 0 0 10px;
  cursor: pointer;
  animation: fadeIn 0.3s linear;
  transition: all 0.3s;
}

.quantidade .total p span:hover {
  background-color: var(--cor-escuro-5);
}

.confirmar {
  display: flex;
  width: 100%;
}

.confirmar button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  background-color: var(--cor-verde);
  margin: 20px 0 0 0;
  padding: 25px;
  transition: all 0.3s;
}

.confirmar button:hover {
  background-color: var(--cor-verde-escuro);
}

.confirmar button h4 {
  font-family: var(--regular);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.confirmar button p {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
}

.extras {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.extras button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-escuro-3);
  padding: 20px;
  width: calc(50% - 7px);
  margin: 20px 0 0 0;
  border-radius: 10px;
  transition: all 0.3s;
}

.extras button:hover {
  background-color: var(--cor-escuro-4);
}

.extras button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.extras button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.extras button.titulos {
  background-color: #f7da0027;
}

.extras button.titulos:hover {
  background-color: #f7da0049;
}

.extras button.titulos svg {
  fill: #f7da00;
}

.extras button.titulos p {
  color: #f7da00;
}

@media screen and (max-width: 1000px) {
  section.comprar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .quadro {
    width: 100%;
    background-color: var(--cor-escuro-1);
    border-radius: 0;
    padding: 20px;
  }

  .topo {
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 0 0 30px 0;
    border-bottom: none;
    background-color: var(--cor-escuro-1);
  }

  .topo .texto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 0 10px;
  }

  .topo .texto h3 {
    font-size: var(--f0);
  }

  .topo .texto p {
    font-size: var(--f2);
  }

  .topo .tempo .item {
    padding: 10px 0 8px 0;
    width: 45px;
    margin: 0 5px 0 0;
  }

  .topo .tempo .item h3 {
    font-size: var(--f2);
  }

  .topo .tempo .item p {
    font-size: var(--f1);
  }

  .quantidade {
    width: 100%;
    padding: 30px 20px;
    border-radius: 10px;
  }

  .quantidade .titulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 0 40px 0;
  }

  .quantidade .titulo h3 {
    font-size: var(--f2);
  }

  .quantidade .titulo p {
    font-size: var(--f3);
  }

  .quantidade .itens .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: calc(25% - 5px);
    padding: 30px 0 0 0;
  }

  .quantidade .itens .item .icone {
    width: 40px;
    height: 40px;
  }

  .quantidade .itens .item h3 {
    font-size: var(--f3);
    margin: 10px 0 20px 0;
  }

  .quantidade .itens .item h4 {
    font-size: var(--f0);
    padding: 5px 10px;
    border-radius: 50px;
    position: absolute;
    top: -15px;
  }

  .quantidade .itens .item button {
    font-size: var(--f0);
    padding: 13px;
  }

  .quantidade .itens .item.popular button {
    color: var(--cor-branco);
    border-top: 1px solid var(--cor-azul);
  }

  .quantidade .total {
    margin: 10px 0 0 0;
  }

  .quantidade .total button {
    width: 70px;
    height: 70px;
  }

  .quantidade .total button svg {
    width: 20px;
    min-width: 20px;
  }

  .quantidade .total p {
    font-size: var(--f3);
  }

  .confirmar button {
    margin: 10px 0 0 0;
    padding: 20px;
  }

  .confirmar button h4 {
    font-size: var(--f3);
  }

  .confirmar button p {
    font-size: var(--f3);
  }

  .extras button {
    justify-content: flex-start;
    width: calc(50% - 5px);
    margin: 10px 0 0 0;
  }

  .extras button:hover {
    background-color: var(--cor-escuro-4);
  }

  .extras button svg {
    width: 13px;
    min-width: 13px;
    margin: 0 8px 0 0;
  }

  .extras button p {
    font-size: var(--f1);
    text-align: left;
    line-height: 1.3;
  }
}
</style>
