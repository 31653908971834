import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiRifas from '../api/app/api-rifas'

export const useStoreRifas = defineStore('storeRifas', {
  state: () => {
    return {
      rifas: null,
      rifa: null,
      filtroBusca: '',
      login: false,
      titulosPremiados: false,
      meusTitulos: false,
      pagarPix: false,
      quantidadeBilhetes: 0,
      totalPagar: 0
    }
  },
  actions: {
    async receberTodos() {
      try {
        const resp = await apiRifas.receberTodos()
        this.rifas = resp
        return resp
      } catch (error) {
        this.rifas = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorId(idRifa) {
      try {
        const resp = await apiRifas.receberPorId(idRifa)
        this.rifa = resp
        return true
      } catch (error) {
        this.rifa = null
        return false
      }
    },

    async receberPorHotlink(hotlink) {
      try {
        const resp = await apiRifas.receberPorHotlink(hotlink)
        this.rifa = resp
        return true
      } catch (error) {
        this.rifa = null
        return false
      }
    }
  }
})
