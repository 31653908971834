<template>
  <nav>
    <div class="logo" @click="voltar()">
      <img src="https://arquivos.oficinadasrifas.com/imagens/logo-v1.svg" />
    </div>
    <div class="opcoes" :class="{ mostrar: state.mostrarMenu }">
      <button @click="irParaRota('sorteios')">
        <Svgs nome="estrela" />
        Sorteios
      </button>
      <button @click="irParaRota('ganhadores')">
        <Svgs nome="medalha" />
        Ganhadores
      </button>
      <button class="destaque" @click="abrirLogin()">
        <Svgs nome="usuario" />
        Ver meus números
      </button>
    </div>
    <button class="menu" @click="state.mostrarMenu = !state.mostrarMenu" :class="{ ativo: state.mostrarMenu }">
      <span class="um"></span>
      <span class="dois"></span>
    </button>
  </nav>
</template>

<script setup>
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStoreRifas } from '@stores'
import Svgs from '@svgs'

const route = useRoute()
const router = useRouter()

const storeRifas = useStoreRifas()

const state = reactive({
  mostrarMenu: false
})

function abrirLogin() {
  storeRifas.login = true
}

function irParaRota(nomeDaRota) {
  const hotlink = route.params.hotlink
  if (hotlink) {
    router.push(`/${hotlink}/${nomeDaRota}`)
  } else {
    console.error('Hotlink não encontrado na URL!')
  }
}
function voltar() {
  const hotlink = route.params.hotlink
  if (hotlink) {
    router.push(`/${hotlink}`)
  } else {
    console.error('Hotlink não encontrado na URL!')
  }
}
</script>

<style scoped>
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  padding: 0 30px;
  background-color: #111112bc;
  border-bottom: 1px solid var(--cor-escuro-4);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}

.logo img {
  width: 100%;
  max-width: 160px;
}

.opcoes {
  display: flex;
  align-items: center;
}

.opcoes button {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: transparent;
  margin: 0 0 0 30px;
  transition: all 0.3s;
}

.opcoes button:hover {
  opacity: 0.7;
}

.opcoes button svg {
  width: 15px;
  min-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

.opcoes button.destaque {
  font-family: var(--bold);
  background-color: var(--cor-azul);
  padding: 10px 16px;
  border-radius: 5px;
  transition: all 0.3s;
}

.opcoes button.destaque:hover {
  opacity: 1;
  background-color: var(--cor-azul-escuro);
}

button.menu {
  display: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  position: relative;
}

button.menu span {
  background-color: var(--cor-branco);
  height: 3px;
  position: absolute;
  transition: all 0.3s;
}

button.menu span.um {
  width: 40px;
  top: 10px;
  right: 0;
}

button.menu span.dois {
  width: 20px;
  bottom: 10px;
  right: 0;
}

button.menu.ativo span {
  background-color: var(--cor-vermelho);
}

button.menu.ativo span.um {
  width: 20px;
  transform: rotate(45deg);
  top: 18px;
}

button.menu.ativo span.dois {
  transform: rotate(-45deg);
  bottom: 18px;
}

/* Responsivo */
@media screen and (max-width: 1000px) {
  nav {
    height: 60px;
    padding: 0 20px;
  }

  .logo img {
    width: 100%;
    max-width: 160px;
  }

  button.menu {
    display: flex;
  }

  .opcoes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    position: fixed;
    top: 65px;
    right: 20px;
    border-radius: 5px;
    background-color: var(--cor-escuro-2);
    border: 1px solid var(--cor-escuro-3);
    opacity: 0;
    visibility: hidden;
    transform: scale(0.9);
    pointer-events: none;
    transition: all 0.3s;
  }

  .opcoes.mostrar {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    pointer-events: all;
  }

  .opcoes button {
    margin: 10px 0;
  }

  .opcoes button svg {
    width: 15px;
    min-width: 15px;
    max-height: 15px;
    fill: var(--cor-branco);
    margin: 0 5px 0 0;
  }

  .opcoes button.destaque {
    font-family: var(--bold);
    background-color: var(--cor-azul);
    padding: 10px 16px;
    border-radius: 5px;
    transition: all 0.3s;
  }

  .opcoes button.destaque:hover {
    opacity: 1;
    background-color: var(--cor-azul-escuro);
  }
}
</style>
